import React, { useState, useEffect } from 'react';
import { getDatabase, ref, set, push, onValue } from 'firebase/database'; // onValue 추가
import { initializeApp } from 'firebase/app';

// Firebase 설정
const firebaseConfig = {
    apiKey: "AIzaSyAeXbkI9YrKaj6hBbFIN9cakvwrdbB4wyU",
    authDomain: "gohaesungsa-26f6b.firebaseapp.com",
    projectId: "gohaesungsa-26f6b",
    storageBucket: "gohaesungsa-26f6b.appspot.com",
    messagingSenderId: "1095489844821",
    appId: "1:1095489844821:web:5bdb5d1909fc61880546dc"
};

// Firebase 초기화
const app = initializeApp(firebaseConfig);
const database = getDatabase(app); // 데이터베이스 초기화

const Test = () => {
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [dataList, setDataList] = useState([]); // 데이터 리스트 상태 추가

    const handleSubmit = () => {
        if (!name || !age || !gender) {
            alert('모든 필드를 채워주세요.');
            return;
        }

        // 새로운 데이터를 추가하기 위한 ref
        const dataRef = ref(database, 'Test/'); // 'Test/' 경로에 대한 참조
        const newRef = push(dataRef); // push() 메서드를 사용하여 고유 키 생성

        set(newRef, {
            name: name,
            age: age,
            gender: gender
        })
            .then(() => {
                alert('데이터가 성공적으로 저장되었습니다!');
                setName('');
                setAge('');
                setGender('');
            })
            .catch((error) => {
                alert('데이터 저장 중 오류가 발생했습니다: ' + error);
            });
    };

    // 데이터를 가져오는 useEffect
    useEffect(() => {
        const dataRef = ref(database, 'Test/');
        onValue(dataRef, (snapshot) => {
            const data = snapshot.val();
            const parsedData = [];

            for (let id in data) {
                parsedData.push({ id, ...data[id] });
            }

            setDataList(parsedData); // 상태 업데이트
        });
    }, [database]);

    return (
        <div>
            <h1>Firebase 데이터 입력</h1>
            <input
                type="text"
                placeholder="이름"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <input
                type="number"
                placeholder="나이"
                value={age}
                onChange={(e) => setAge(e.target.value)}
            />
            <input
                type="text"
                placeholder="성별"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
            />
            <button onClick={handleSubmit}>저장</button>

            <h2>저장된 데이터:</h2>
            <ul>
                {dataList.map((item) => (
                    <li key={item.id}>
                        {item.name}, {item.age}세, {item.gender}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Test;
