import React, { useEffect, useState } from 'react';
import './Modal.css';

const Modal = ({ isOpen, onClose, children }) => {
    const [animationClass, setAnimationClass] = useState('');

    useEffect(() => {
        if (isOpen) {
            setAnimationClass('slide-down'); // 모달이 열릴 때 슬라이드다운
        } else {
            setAnimationClass('slide-up'); // 모달이 닫힐 때 슬라이드업
        }
    }, [isOpen]);

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div
            className={`modal-overlay ${isOpen ? 'open' : ''}`}
            onClick={handleOverlayClick}
        >
            <div className={`modal-content ${animationClass}`}>
                {children}
            </div>
        </div>
    );
};

export default Modal;
