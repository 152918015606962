import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./Home";
import Test from "./Test";
// import InputTest from "./InputTest";

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Home/>}/>
          {/*<Route path="/" element={<Test/>}/>*/}
        </Routes>
      </Router>
  );
}

export default App;
