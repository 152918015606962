import React, { useState, useEffect } from 'react';
import "./Home.css";
import { ref, set, getDatabase, onValue,  get, child, update  } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';
import Lottie from "lottie-react";
import loadingLottie from "./fire.json";
import MyImage from "./Logo.png";
import BETA from "./BETA.png";
import NoticeBoardComponent from './NoticeBoardComponent';
import HotComponent from "./HotComponent";
import Modal from './Modal';

const Home = () => {
    const [crimeText, setCrimeText] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [nickname, setNickname] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [crimes, setCrimes] = useState([]);
    const [hottestCrime, setHottestCrime] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sortType, setSortType] = useState('recent');
    const [searchText, setSearchText] = useState('');
    const [ipCount, setipCount] = useState(0);
    const db = getDatabase();
    const crimesRef = ref(db, 'CrimeCollection');

    const handleTextChange = (e) => {
        const inputText = e.target.value;
        setCrimeText(inputText);

        if (inputText.length > 2000) {
            setErrorMessage("2000자를 초과하였습니다!");
        } else if (inputText.length > 0 && inputText.length < 10) {
            setErrorMessage("고해성사는 10자 이상부터 할 수 있습니다.");
        } else {
            setErrorMessage("");
        }
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const submitSearch = () => {
        onValue(crimesRef, (snapshot) => {
            const data = snapshot.val();
            const loadedCrimes = [];
            let maxCrime = null;

            for (let id in data) {
                const crimeData = { id, ...data[id] };
                if (crimeData.crime.includes(searchText) || crimeData.nickname.includes(searchText)) {
                    loadedCrimes.push(crimeData);
                }

                const totalVotes = crimeData.forgive + crimeData.notforgive;
                if (!maxCrime || totalVotes > (maxCrime.forgive + maxCrime.notforgive)) {
                    maxCrime = crimeData;
                }
            }
            setHottestCrime(maxCrime);
            applySort(loadedCrimes, sortType);
        });
    };

    const submitCrime = async () => {
        if (!nickname || !password || crimeText.trim().length < 30 || crimeText.length > 2000) {
            setErrorMessage("올바른 정보를 입력해주세요.");
            return;
        }

        const crimeId = uuidv4();
        const newCrimeRef = ref(db, `CrimeCollection/${crimeId}`);
        const today = new Date().toISOString().replace('T', ' ').slice(0, 19);

        await set(newCrimeRef, {
            crime: crimeText,
            day: today,
            forgive: 0,
            notforgive: 0,
            nickname,
            password,
            comment:"..."
        });

        setCrimeText("");
        setNickname("");
        setPassword("");
        alert("고해성사에 등록되었습니다.");
        setIsModalOpen(false);
    };


    const fetchAndSaveIpAddress = async () => {
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            const ipToSave = data.ip;
            const ipRef = ref(db, `IP`);

            // 기존 IP 데이터 확인
            onValue(ipRef, async (snapshot) => {
                const ipData = snapshot.val() || {};
                const existingIp = Object.values(ipData).find(entry => entry.ipAddress === ipToSave);

                if (existingIp) {
                    console.log("중복된 IP입니다. 추가하지 않습니다:", ipToSave);
                    const newIpCount = Object.keys(ipData).length ;
                    console.log("현재 IP 데이터 개수:", newIpCount);
                    setipCount(newIpCount); // 상태 업데이트



                } else {
                    const newIpRef = ref(db, `IP/${uuidv4()}`);
                    await set(newIpRef, {
                        ipAddress: ipToSave,
                        timestamp: new Date().toISOString(),
                    });
                    console.log("IP 주소가 데이터베이스에 저장되었습니다:", ipToSave);

                    // IP 개수 업데이트
                    const newIpCount = Object.keys(ipData).length + 1; // 기존 개수에 1 추가
                    console.log("현재 IP 데이터 개수:", newIpCount);
                    setipCount(newIpCount); // 상태 업데이트
                }
            }, {
                onlyOnce: true,
            });
        } catch (error) {
            console.error("IP를 가져오는 도중 오류가 발생했습니다.", error);
        }
    };

    useEffect(() => {
        fetchAndSaveIpAddress();
    }, []);



    useEffect(() => {
        onValue(crimesRef, (snapshot) => {
            const data = snapshot.val();
            const loadedCrimes = [];
            let maxCrime = null;

            for (let id in data) {
                const crimeData = { id, ...data[id] };
                if (crimeData.crime.includes(searchText) || crimeData.nickname.includes(searchText)) {
                    loadedCrimes.push(crimeData);
                }

                const totalVotes = crimeData.forgive + crimeData.notforgive;
                if (!maxCrime || totalVotes > (maxCrime.forgive + maxCrime.notforgive)) {
                    maxCrime = crimeData;
                }
            }
            setHottestCrime(maxCrime);
            applySort(loadedCrimes, sortType);
        });
    }, [sortType, searchText]);

    const updateCrimeCount = async (id, field, value) => {
        const crimeRef = ref(db, `CrimeCollection/${id}`);
        await update(crimeRef, { [field]: value });
    };

    const applySort = (crimesList, type) => {
        let sortedCrimes;
        switch (type) {
            case 'popularity':
                sortedCrimes = [...crimesList].sort((a, b) =>
                    (b.forgive + b.notforgive) - (a.forgive + a.notforgive)
                );
                break;
            case 'recent':
                sortedCrimes = [...crimesList].sort((a, b) =>
                    new Date(b.day) - new Date(a.day)
                );
                break;
            case 'forgive':
                sortedCrimes = [...crimesList].sort((a, b) => b.forgive - a.forgive);
                break;
            case 'notForgive':
                sortedCrimes = [...crimesList].sort((a, b) => b.notforgive - a.notforgive);
                break;
            default:
                sortedCrimes = crimesList;
        }
        setCrimes(sortedCrimes);
    };



    const test = async () => {
        const db = getDatabase();
        const crimeCollectionRef = ref(db, 'CrimeCollection');

        try {
            // 1. CrimeCollection의 모든 문서 가져오기
            const snapshot = await get(crimeCollectionRef);

            if (snapshot.exists()) {
                const crimeData = snapshot.val();

                // 2. 각 문서에 countIp 추가
                Object.keys(crimeData).forEach(async (crimeId) => {
                    const countIpRef = child(crimeCollectionRef, `${crimeId}/countIp`);

                    // forgiveIp와 notforgiveIp를 문자열 타입으로 설정
                    await update(countIpRef, {
                        forgiveIp: "",      // 초기값 빈 문자열
                        notforgiveIp: ""    // 초기값 빈 문자열
                    });
                });

                console.log('모든 문서에 countIp 데이터가 추가되었습니다.');
            } else {
                console.log("CrimeCollection에 데이터가 없습니다.");
            }
        } catch (error) {
            console.error("countIp 데이터 추가 중 오류 발생:", error);
        }
    };


    return (
        <div className={'Home'}>

            <div className={'top-bar'}>
                <img src={MyImage} alt="Example" className={'logo'}/>
                <span className={'top-bar-text'}>GHSS</span>
                <img src={BETA} alt="Example" className={'BETA'}/>
                <div className={'title-box'}>
                <div className={'title'}>당신의 죄를 용서 받으세요</div>
                </div>
                <button className={'gohaesungsa'} onClick={() => setIsModalOpen(true)}>
                    나도 고해성사
                </button>
            </div>

            <div className={'background'}>

                <div className={'center-box'}>

                    <Lottie animationData={loadingLottie} className={'lottie-animation'}/>
                    <div className={'HOT-TEXT'}>HOT한 고백</div>
                    <div className={'serch'}>
                        <input
                            type="text"
                            placeholder="검색어를 입력하세요"
                            className="search-input"
                            value={searchText}
                            onChange={handleSearchChange} // 검색어 입력 핸들러 추가
                        />
                        <button className="search-button" onClick={submitSearch}>검색</button>
                    </div>

                    {hottestCrime ? (
                        <HotComponent
                            id={hottestCrime.id}
                            text={hottestCrime.crime}
                            initialForgiveCount={hottestCrime.forgive}
                            initialNotForgiveCount={hottestCrime.notforgive}
                            date={hottestCrime.day}

                        />
                    ) : (
                        <p>로딩 중...</p>
                    )}

                    <div className={'notice-top-bar'}>
                        <button className={'Popular-button'} onClick={() => setSortType('popularity')}>
                            인기순
                        </button>
                        <button className={'Real-time-button'} onClick={() => setSortType('recent')}>
                            최근순
                        </button>
                        <button className={'Forgive-sort-button'} onClick={() => setSortType('forgive')}>
                            용서가 많은 순
                        </button>
                        <button className={'NotForgive-sort-button'} onClick={() => setSortType('notForgive')}>
                            님이 많은 순
                        </button>
                    </div>
                    <div className={'line'}></div>

                    <div className={'notice-board-box'}>
                        {crimes.map((crime) => (
                            <NoticeBoardComponent
                                key={crime.key}
                                id={crime.id}
                                text={crime.crime}
                                initialForgiveCount={crime.forgive}
                                initialNotForgiveCount={crime.notforgive}
                                date={crime.day}
                                name={crime.nickname}
                                updateCrimeCount={updateCrimeCount}
                                comment={crime.comment}
                            />
                        ))}
                    </div>
                </div>
            </div>

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <div className="create-crime">
                    <div className={'text1'}>당신의 죄는 용서 받을 수 있습니다.</div>
                    <div className={'text1'}>자신있게 고해성사 하세요</div>
                    <div className={'warning-text'}>(닉네임은 사람들에게 공개 되며, 비밀번호는 글을 삭제할때 사용됩니다.)</div>
                    <div className={'textarea-box'}>
                        <input
                            className={'nickname'}
                            type="text"
                            placeholder="닉네임을 입력해주세요."
                            maxLength={20}
                            value={nickname}
                            onChange={(e) => setNickname(e.target.value)} // 닉네임 상태 업데이트

                        />
                        <input
                            className={'password'}
                            type={showPassword ? 'text' : 'password'}
                            placeholder="비밀번호를 입력해주세요."
                            maxLength={20}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}

                        />
                        <button onClick={() => setShowPassword(!showPassword)} className={'pwbutton'}>
                            {showPassword ? '숨기기' : '보기'}
                        </button>
                    </div>

                    <textarea

                        placeholder="최대 2000자까지 입력 가능합니다."
                        className="crime-textarea"
                        maxLength={2000}
                        value={crimeText}
                        onChange={handleTextChange}
                    />
                    {errorMessage && (
                        <p style={{color: 'red'}}>{errorMessage}</p>
                    )}
                    <div className={'create-crime-button-text'}>
                        너무 자극적인 단어를 사용할 경우, 해당 내용이 삭제될 수 있습니다. </div>
                    <button className="create-crime-button" onClick={submitCrime}>
                        고해성사
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default Home;
