import React, { useState, useEffect } from 'react';
import './HotComponent.css';
import { getDatabase, ref, update,crimeRef } from 'firebase/database';

const formatDate = (dateString) => {
    const formattedString = dateString.replace(" ", "T");
    const test = formattedString.split("T");
    const date = test[0].split("-");
    const year = date[0];
    const month = date[1];
    const day = date[2];

    return `${year}년 ${month}월 ${day}일`;
};

const Hotcomponent = ({ id, text, initialForgiveCount, initialNotForgiveCount, date }) => {
    const [forgiveCount, setForgiveCount] = useState(initialForgiveCount);
    const [notForgiveCount, setNotForgiveCount] = useState(initialNotForgiveCount);
    const [loading, setLoading] = useState(false);
    const [isForgiveDisabled, setIsForgiveDisabled] = useState(false); // 용서 버튼 비활성화 상태
    const [isNotForgiveDisabled, setIsNotForgiveDisabled] = useState(false); // 용서하지 않음 버튼 비활성화 상태
    useEffect(() => {
        setForgiveCount(initialForgiveCount);
        setNotForgiveCount(initialNotForgiveCount);
    }, [initialForgiveCount, initialNotForgiveCount]);

    const db = getDatabase();

    const handleForgive = async () => {


        setIsForgiveDisabled(true); // 버튼 비활성화
        const newForgiveCount = forgiveCount + 1;
        setForgiveCount(newForgiveCount);
        const crimeRef = ref(db, `CrimeCollection/${id}`);

        await update(crimeRef, { forgive: newForgiveCount });


        setTimeout(() => {
            setIsForgiveDisabled(false);
        }, 60000);
    };

    const handleNotForgive = async () => {
        setIsNotForgiveDisabled(true); // 버튼 비활성화
        const newNotForgiveCount = notForgiveCount + 1;
        setNotForgiveCount(newNotForgiveCount);
        const crimeRef = ref(db, `CrimeCollection/${id}`);

        await update(crimeRef, { notforgive: newNotForgiveCount });

        setTimeout(() => {
            setIsNotForgiveDisabled(false);
        }, 60000);
    };
    return (
        <div className="forgiveness-container">
            <div className={'theday'}>사건 발생 날짜</div>
            <p className="incident-date">{formatDate(date)}</p>
            <div className="incident-text">{text}</div>

            <div className="buttons-container">
                <div className={'forgiveCountBox'}>
                    <div className="forgiveness-count">{forgiveCount}</div>
                    <button className="hot-forgive-button"
                            onClick={() => {
                                if (isForgiveDisabled) {
                                    alert("너무 자주 용서하지 말아 주세요.\n버릇이 나빠집니다.");
                                } else {
                                    handleForgive();
                                }
                            }}

                            >용서합니다.</button>
                </div>
                <div className={'notforgiveCountBox'}>
                    <div className="not-forgiveness-count">{notForgiveCount}</div>
                    <button className="hot-not-forgive-button"
                            onClick={() => {
                                if (isNotForgiveDisabled) {
                                    alert("너무 미워하지 말아요\n애가 그럴수도 있죠.");
                                } else {
                                    handleNotForgive();
                                }
                            }}
                           >님신고.</button>
                </div>
            </div>
        </div>
    );
};

export default Hotcomponent;
