import React, { useState, useEffect } from 'react';
import { ref, get, getDatabase, remove } from 'firebase/database';
import './NoticeBoardComponent.css';
import ModalComponent from './Modal';
import coments from "./coments.png";

// 날짜를 포맷하는 함수
const formatDate = (dateString) => {
    const formattedString = dateString.replace(" ", "T");
    const test = formattedString.split("T");
    const date = test[0].split("-");
    const year = date[0];
    const month = date[1];
    const day = date[2];
    return `${year}년 ${month}월 ${day}일`;
};

const NoticeBoardComponent = ({ id, text, initialForgiveCount, initialNotForgiveCount, date, name, updateCrimeCount, comment }) => {
    const [forgiveCount, setForgiveCount] = useState(initialForgiveCount);
    const [notForgiveCount, setNotForgiveCount] = useState(initialNotForgiveCount);
    const [forgiveAnimation, setForgiveAnimation] = useState(false);
    const [notForgiveAnimation, setNotForgiveAnimation] = useState(false);
    const [isForgiveDisabled, setIsForgiveDisabled] = useState(false); // 용서 버튼 비활성화 상태
    const [isNotForgiveDisabled, setIsNotForgiveDisabled] = useState(false); // 용서하지 않음 버튼 비활성화 상태
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [message, setMessage] = useState('');
    const db = getDatabase();

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setErrorMessage('');
    };

    const handleSubmit = async () => {
        try {
            const snapshot = await get(ref(db, `CrimeCollection/${id}`));
            if (snapshot.exists()) {
                const data = snapshot.val();
                if (data.password === password) {
                    setMessage("비밀번호가 일치합니다. 데이터를 삭제합니다.");
                    await remove(ref(db, `CrimeCollection/${id}`));
                    setMessage("데이터가 성공적으로 삭제되었습니다.");
                } else {
                    setErrorMessage("비밀번호가 일치하지 않습니다.");
                }
            } else {
                setMessage("해당 데이터가 없습니다.");
            }
        } catch (error) {
            console.error('데이터 가져오기 또는 삭제 실패:', error);
            setErrorMessage('데이터를 처리하는 데 오류가 발생했습니다.');
        }
    };

    useEffect(() => {
        setForgiveCount(initialForgiveCount);
        triggerForgiveAnimation();
    }, [initialForgiveCount]);

    useEffect(() => {
        setNotForgiveCount(initialNotForgiveCount);
        triggerNotForgiveAnimation();
    }, [initialNotForgiveCount]);

    const triggerForgiveAnimation = () => {
        setForgiveAnimation(true);
        setTimeout(() => setForgiveAnimation(false), 1000);
    };

    const triggerNotForgiveAnimation = () => {
        setNotForgiveAnimation(true);
        setTimeout(() => setNotForgiveAnimation(false), 1000);
    };

    const handleForgive = async () => {


        setIsForgiveDisabled(true); // 버튼 비활성화
        const newForgiveCount = forgiveCount + 1;
        setForgiveCount(newForgiveCount);
        triggerForgiveAnimation();
        await updateCrimeCount(id, 'forgive', newForgiveCount);

        setTimeout(() => {
            setIsForgiveDisabled(false);
        }, 60000);
    };

    const handleNotForgive = async () => {
        setIsNotForgiveDisabled(true); // 버튼 비활성화
        const newNotForgiveCount = notForgiveCount + 1;
        setNotForgiveCount(newNotForgiveCount);
        triggerNotForgiveAnimation();
        await updateCrimeCount(id, 'notforgive', newNotForgiveCount);

        setTimeout(() => {
            setIsNotForgiveDisabled(false);
        }, 60000);
    };

    const handleLinkImageClick = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setMessage("");
        setPassword("");
        setErrorMessage("");
    };

    return (
        <div className="horizontal-border">
            <div className="container">
                <div className="link-image" onClick={handleLinkImageClick}/>
                <div className="link">
                    <div className="text-wrapper">{name}</div>
                </div>
                <div className="div">{formatDate(date)}</div>
                <div className="link-2">{text}</div>
                <div className="count-box">
                    <button
                        className="forgive"
                        onClick={() => {
                            if (isForgiveDisabled) {
                                alert("너무 자주 용서하지 말아 주세요.\n버릇이 나빠집니다.");
                            } else {
                                handleForgive();
                            }
                        }}
                    >
                        용서합니다.
                    </button>
                    <div className={`count ${forgiveAnimation ? 'animate' : 'animate-back'}`}>
                        {forgiveCount}
                    </div>
                    <button
                        className="notforgive"
                        onClick={() => {
                            if (isNotForgiveDisabled) {
                                alert("너무 미워하지 말아요\n애가 그럴수도 있죠.");
                            } else {
                                handleNotForgive();
                            }
                        }}
                    >
                        님.
                    </button>
                    <div className={`count ${notForgiveAnimation ? 'animate' : 'animate-back'}`}>
                        {notForgiveCount}
                    </div>
                </div>
                <div className={'the-line-box'}>
                    <div className={'the-line'}></div>
                </div>
                <div className={'comments-box'}>
                    <img src={coments} alt="설명 텍스트" className={'coment-image'}/>
                    <div className={'permission-coments'}>{comment}</div>
                </div>
            </div>

            <ModalComponent isOpen={isModalOpen} onClose={closeModal}>
                <div className="modal-background">
                    <div className="modal-box">
                        <div className={'delete-text1'}>반성은 충분히 하셨나요?</div>
                        <div className={'delete-text2'}>고해성사 당시에 입력했던 비밀번호를 입력해주세요.</div>
                        <input
                            className={'delete-input'}
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                            placeholder="비밀번호 입력"
                        />
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                        <div className={'notice-button-box'}>
                            <button onClick={handleSubmit} className={'submitBtn'}>제출</button>
                            <button onClick={closeModal} className={'closeBtn'}>닫기</button>
                        </div>
                        {message && <p className="message">{message}</p>}
                    </div>
                </div>
            </ModalComponent>
        </div>
    );
};

export default NoticeBoardComponent;
